export const state = {
  jobs: [],
  selectedJob: null,
}

export const mutations = {
  selectJob (state, job) {
    state.selectedJob = job
  },
  clearSelectedJob (state) {
    state.selectedJob = null
  }
}
